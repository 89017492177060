<template>
  <validation-observer
    ref="classStudentSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="classStudentSaveModal"
      centered
      body-class="position-static"
      no-close-on-backdrop
      size="lg"
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật lớp ${resourceName.fullName}`"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group label-for="classId">
              <template v-slot:label>
                Lớp cố định <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Lớp cố định"
                rules="required"
              >
                <v-select
                  v-model="targetData.classId"
                  :options="classes"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            lg="3"
          >
            <b-form-group
              label="Chức danh"
              label-for="titleId"
            >
              <v-select
                v-model="targetData.titleId"
                :options="titles"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            lg="3"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái học"
                rules="required"
              >
                <v-select
                  v-model="targetData.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('classStudentSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export const Attr = Object.freeze({
  store: 'studentClass',
})
export default {
  name: 'ClassStudentSave',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    extData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        id: 0,
        departmentId: 0,
        majorId: 0,
        classId: 0,
        studentClassId: 0,
        absentTime: 0,
        isEligible: 1,
        orderNo: 0,
        status: 1,
        titleId: null,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: `${Attr.store}/statuses`,
      eligible: `${Attr.store}/eligible`,
      resourceName: `${Attr.store}/resourceName`,
      maxOrderNo: `${Attr.store}/maxOrderNo`,
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      classes: 'dropdown/classes',
      titles: 'dropdown/titles',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: `${Attr.store}/createData`,
      updateData: `${Attr.store}/updateData`,
      getMaxOrderNo: `${Attr.store}/getMaxOrderNo`,
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getClasses: 'dropdown/getClasses',
      getTitles: 'dropdown/getTitles',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },

    async onShow() {
      this.isLoading = true
      const cUser = getUser()
      const userOrgId = cUser.orgId ? cUser.orgId : 0
      if (this.mainData) {
        this.targetData = this.mainData
      }
      if (this.isCreated) {
        if (this.departments.length > 0) {
          this.targetData.departmentId = this.departments[0].value
        }
        this.targetData.orderNo = this.maxOrderNo + 1
      } else {
        await this.getClasses({ majorId: this.targetData.majorId })
        await this.getTitles({ studentOnly: 1 })
      }

      this.isLoading = false
    },
    onHide() {
      this.$refs
        .classStudentSaveFormRef
        .reset()
      this.targetData = {
        id: 0,
        classId: 0,
        studentClassId: 0,
        absentTime: 0,
        isEligible: 1,
        orderNo: 0,
        status: 1,
        titleId: '',
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .classStudentSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('classStudentSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
