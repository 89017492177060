<template>
  <validation-observer
    ref="studentProgrammeFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="studentProgrammeSaveModal"
      body-class="position-static"
      centered
      no-close-on-backdrop
      title="Phân chương trình đào tạo cho học viên"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="name">
          <template v-slot:label>
            Chương trình đào tạo <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Chương trình đào tạo"
            rules="required"
          >
            <v-select
              id="majorId"
              v-model="programmeId"
              label="name"
              :options="programmes"
              :reduce="option => option.id"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('studentProgrammeSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'StudentProgramme',
  directives: {
    Ripple,
  },
  components: {
    BOverlay,
    BModal,
    BButton,
    ValidationProvider,
    BForm,
    BFormGroup,
    ValidationObserver,
    vSelect,
  },
  props: {
    courseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      majorId: null,
      programmeId: null,
    }
  },
  computed: {
    ...mapGetters({
      majors: 'studentClass/majors',
      programmes: 'studentClass/programmes',
    }),
  },
  watch: {
  },
  methods: {
    ...mapActions({
      getMajorsByOrganizationId: 'studentClass/getMajorsByOrganizationId',
      getProgrammesByCourseId: 'studentClass/getProgrammesByCourseId',
    }),
    async onShow() {
      this.isLoading = true
      try {
        await this.getProgrammesByCourseId({ courseId: this.courseId })
        this.programmeId = this.programmes.length > 0 ? this.programmes[0].id : null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .studentProgrammeFormRef
        .reset()
      this.majorId = null
      this.programmeId = null
    },
    onSave() {
      this.$emit('save', this.programmeId)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
