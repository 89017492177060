<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <div class="mt-1 mb-0 mx-2">
          <b-form>
            <b-row>
              <b-col cols="4">
                <b-row>
                  <b-col cols="12">
                    <div>
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-modal.studentReserveSaveModal
                          variant="primary"
                          @click="onCreateStudentAllowance"
                      >
                      <span class="text-nowrap text-right">Thêm bảo lưu</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="8">
                <b-row>
                  <b-col
                      cols="4"
                      class="text-right mt-1"
                  >
                    <label>Học kỳ</label>
                  </b-col>
                  <b-col
                      cols="8"
                      class="text-right"
                  >
                    <v-select
                        v-model="filter.course_semester_id"
                        :options="courseSemesters"
                        :reduce="option => option.value"
                        @input="getMainDataFromStore"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </div>

        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row class="">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  :columns="columns"
                  :rows="dataLists"
                  :pagination-options="paginationOptions"
                  style-class="my-table vgt-table striped bordered"
                  @on-column-filter="onColumnFilter"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'action'">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class=""
                        variant="danger"
                        size="sm"
                        @click="deleteStudentReserve(props.row)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <StudentReserveSave
        ref="studentReserveSaveFormRef"
        :propsData="dataSend"
        @succeed="onSucceed"
    />
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BRow, BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import StudentReserveSave from '@/views/student-class/student-reserve/StudentReserveSave.vue'

export const Attr = Object.freeze({
  store: 'studentClass',
})
export default {
  name: 'StudentReserve',
  directives: {
    Ripple,
  },
  components: {
    StudentReserveSave,
    BForm,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BRow,
    VueGoodTable,
    vSelect,
  },
  props: {
    dataSendClass: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        class_id: this.$route.params.id || this.dataSendClass.id,
        course_semester_id: 0,
        code: '',
        name: '',
      },
      currentMainData: undefined,
      paginationOptions: {
        enabled: false,
      },
      dataSend: { courseSemesterId: 0 },
    }
  },
  computed: {
    ...mapGetters({
      dataLists: 'studentReserve/dataLists',
      courseSemesters: 'dropdown/courseSemesters',
    }),
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Mã lớp',
          field: 'classCode',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Họ tên',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Mã',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Từ ngày',
          field: 'start_date',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Đến ngày',
          field: 'expire_date',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lý do',
          field: 'reason',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          maxWidth: '60px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  async created() {
    await this.getCourseSemester({ courseId: this.dataSendClass.courseId, status: null })
    this.filter.course_semester_id = this.courseSemesters.find(item => item.status === 1).value

    this.isLoading = true
    try {
      await Promise.all([
        this.getMainData(this.filter),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: 'studentReserve/getData',
      getCourseSemester: 'dropdown/getCourseSemestersNew',
      deteleStudentReserve: 'studentReserve/deleteStudentReserve',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      await this.getMainDataFromStore()
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    onCreateStudentAllowance() {
      this.dataSend.class_id = this.filter.class_id
      this.dataSend.courseSemesterId = this.filter.course_semester_id
    },
    deleteStudentReserve(item) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa bảo lưu?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deteleStudentReserve({ id: item.id, status_course_semester: item.status_course_semester, student_class_id: item.student_class_id })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainDataFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    }
  },
}
</script>
