<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="teacherClassSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm Giảng viên chủ nhiệm' : 'Cập nhật Giảng viên chủ nhiệm'"
      @show="onShow"
      @hide="onHide"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label-for="name">
          <template v-slot:label>
            Khoa/bộ môn <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Khoa/bộ môn"
            rules="required"
          >
            <v-select
              id="departmentId"
              v-model="targetItem.departmentId"
              label="name"
              :options="departments"
              :reduce="option => option.id"
              :clearable="false"
              @input="changeDepartment"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="teacherId">
          <template v-slot:label>
            Giảng viên chủ nhiệm <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Giảng viên chủ nhiệm"
            rules="required"
          >
            <v-select
              id="teacherId"
              v-model="targetItem.teacherId"
              :options="teachers"
              :reduce="option => option.value"
              :clearable="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="lessonNum"
        >
          <template v-slot:label>
            Số tiết thực hiện <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Số tiết thực hiện"
            rules="required"
          >
            <b-form-input
              id="lessonNum"
              v-model="targetItem.lessonNum"
              name="lessonNum"
              type="number"
              placeholder="Số tiết thực hiện"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="startDate"
        >
          <template v-slot:label>
            Ngày bắt đầu <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Ngày bắt đầu"
            rules="required"
          >
            <flat-pickr
              v-model="targetItem.startDate"
              class="form-control"
              :config="config"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="endDate"
        >
          <template v-slot:label>
            Ngày kết thúc <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Ngày kết thúc"
            rules="required"
          >
            <flat-pickr
              v-model="targetItem.endDate"
              class="form-control"
              :config="config"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('teacherClassSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from 'flatpickr/dist/l10n'

export default {
  name: 'DepartmentSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
    classId: {
      type: Number,
      default: undefined,
    }
  },
  data() {
    return {
      isLoading: false,
      targetItem: {
        departmentId: null,
        classId: null,
        teacherId: null,
        lessonNum: null,
        startDate: null,
        endDate: null,
        status: 1,
      },
      required,
      config: {
        altFormat: 'm/Y',
        dateFormat: 'm/Y',
        locale: FlatpickrLanguages.vn,
      },
    }
  },
  computed: {
    ...mapGetters({
      departments: 'teacherClass/departments',
      teachers: 'teacherClass/teachers',
    }),
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      create: 'teacherClass/createData',
      update: 'teacherClass/updateData',
      getDepartments: 'teacherClass/getDepartments',
      getTeachers: 'teacherClass/getTeachers',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.loading = true;
      const dataObj = {
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: getUser().orgId,
      };
      try {
        await this.getDepartments(dataObj);
        if (this.item) {
          this.targetItem = { ...this.item };
          this.targetItem.departmentId = this.item.departmentId;
          this.targetItem.teacherId = this.item.teacherId;
          const param = {
            organizationId: getUser().orgId,
            departmentId: this.targetItem.departmentId,
          };
          await this.getTeachers(param);
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false;
      }
    },
    async changeDepartment(id) {
      const param = {
        organizationId: getUser().orgId,
        departmentId: id,
      }
      try {
        await this.getTeachers(param);
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false;
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        departmentId: null,
        classId: null,
        teacherId: null,
        lessonNum: null,
        startDate: null,
        endDate: null,
        status: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          this.targetItem.classId = this.isCreated ? this.classId : this.item.classId
          const lastFourDate1 = this.targetItem.startDate.slice(-4)
          const lastFourDate2 = this.targetItem.endDate.slice(-4)
          if (lastFourDate1 !== lastFourDate2) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thời gian bắt đầu và thời gian kết thúc phải trong cùng năm',
                icon: 'warning',
                variant: 'warning',
              },
            })
          } else {
            const response = this.isCreated
              ? await this.create(this.targetItem)
              : await this.update(this.targetItem)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                if (type === 'hide') {
                  this.$bvModal.hide('teacherClassSaveModal')
                }
                this.$emit('succeed')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
